<template>
  <div>
    <b-card>
      <b-form
        style="max-width: 1024px;"
        @submit.prevent
      >
        <b-row class="mt-1 text-left text-md-center align-items-top align-content-start">
          <b-col
            v-if="$store.state.app.isMaster($store)"
            cols="12"
            md="6"
          >
            <b-form-group
              label="Prefix"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="prefix"
                v-model="form.prefix"
                placeholder="Prefix"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="$store.state.app.isMaster($store)"
            cols="12"
            md="5"
          />
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="mc-username"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="mc-username"
                v-model="form.username"
                placeholder="Username"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="เหตุการณ์"
              label-for="mc-type"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <v-select
                v-model="form.type"
                label="title"
                :options="eventTypes"
                placeholder="Event type"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Round Id"
              label-for="RoundId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="RoundId"
                v-model="form.roundId"
                placeholder="Round Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Poy Id"
              label-for="PoyId"
              label-cols-lg="3"
              label-cols-md="4"
            >
              <b-form-input
                id="PoyId"
                v-model="form.betId"
                placeholder="Poy Id"
                @keydown.enter="onSubmit"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="text-right"
          >
            <div
              role="group"
              class="form-row form-group"
            >
              <label
                for="RoundId"
                class="col-md-4 col-lg-3 col-form-label"
              />
              <div class="col text-left">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  @click.prevent="onSubmit"
                >
                  ค้นหา
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="danger"
                  style="margin-left: 15px;"
                  @click.prevent="onSubmitPending"
                >
                  ส่งอีกครั้ง
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        striped
        hover
        responsive
        bordered
        :per-page="perPage"
        :current-page="currentPage"
        :items="reloadData"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :busy.sync="loading"
        :filter="filter"
      >
        <template #cell(username)="v">
          <div>
            <span
              style="text-decoration: underline;cursor: pointer"
              class="font-weight-bolder"
              @click="showUserDetial(v.value)"
            >{{ v.value }}</span><br>@{{ v.item.prefix }}
          </div>
        </template>
        <template #cell(type)="v">
          <span
            class="font-weight-bolder"
          >
            {{ v.value }}
          </span>
        </template>
        <template #cell(roundId)="v">
          <div class="text-effect">
            {{ v.value }}
          </div>
        </template>
        <template #cell(poyId)="v">
          <div class="text-effect">
            {{ v.value }}
          </div>
        </template>
        <template #cell(refId)="v">
          <div class="text-effect">
            {{ v.value }}
          </div>
        </template>
        <template #cell(status)="v">
          <div class="text-effect">
            {{ v.value }}
          </div>
        </template>

        <template #cell(edit)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="$router.push(`agents/edit?id=${data.item.username}`)"
          >
            <feather-icon icon="Edit3Icon" />
          </b-button>
        </template>

        <template #cell(delete)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            @click="onDelete(data.item.username)"
          >
            <feather-icon icon="ArchiveIcon" />
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col
          cols="2"
        >
          <b-form-group class="text-nowrap mb-0">
            <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
              style="min-width: 100px"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          lg="8"
          md="6"
          sm="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import loggingService from '@/service/loggingService'

import vSelect from 'vue-select'
import store from '@/store'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BForm,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      form: {
        prefix: '',
        username: '',
        roundId: '',
        betId: '',
        type: '',
      },
      eventTypes: ['ทั้งหมด', 'BET', 'PAYOUT', 'CANCEL', 'CANCEL_NUMBER', 'VOID'],
      loading: false,
      perPage: 25,
      pageOptions: [1, 5, 10, 25, 50, 100, 150, 250, 500],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createAt',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterForSearch: '',
      // filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'index',
          label: 'ลำดับ',
        },
        {
          key: 'createAt',
          label: 'Date',
          class: 'text-wrap',
        },
        // {
        //   key: 'agent',
        //   label: 'agent',
        // },
        // {
        //   key: 'roundId',
        //   label: 'round',
        // },
        // {
        //   key: 'betId',
        //   label: 'poyId',
        // },
        {
          key: 'username',
          label: 'Username',
        },
        {
          key: 'type',
          label: 'event',
        },
        {
          key: 'roundId',
          label: 'roundId',
        },
        {
          key: 'poyId',
          label: 'poyId ',
        },
        {
          key: 'refId',
          label: 'refId',
          class: 'text-center text-nowrap',
        },
        {
          key: 'status',
          label: 'status',
        },
      ],
    }
  },
  mounted() {
    if (this.$store.state.app.isMaster(store) === false) {
      this.$router.push('404')
    }
  },
  methods: {
    onSubmit() {
      const {
        prefix,
        username,
        type,
        roundId,
        betId,
      } = this.form

      this.filter = ''
      if (prefix !== '') {
        this.filter += `&prefix=${prefix}`
      }

      if (username !== '') {
        this.filter += `&username=${username}`
      }

      if (type !== '' && type !== 'ทั้งหมด') {
        this.filter += `&type=${type}`
      }

      if (roundId !== '') {
        this.filter += `&round=${roundId}`
      }

      if (betId !== '') {
        this.filter += `&poy=${betId}`
      }

      this.$refs.table.refresh()
    },
    onSubmitPending() {
      const {
        prefix,
        username,
        type,
        roundId,
        betId,
      } = this.form
      if (prefix === '') {
        this.$swal({
          title: 'Error!',
          text: 'prefix can not empty',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณต้องการเตรียมส่งข้อมูลอีกครั้งใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showLoaderOnConfirm: true,
      })
        .then(async result => {
          if (result.value) {
            this.$swal.showLoading()
            let filter = ''
            if (prefix !== '') {
              filter += `&prefix=${prefix}`
            }

            if (username !== '') {
              filter += `&username=${username}`
            }

            if (type !== '' && type !== 'ทั้งหมด') {
              filter += `&type=${type}`
            }

            if (roundId !== '') {
              filter += `&round=${roundId}`
            }

            if (betId !== '') {
              filter += `&poy=${betId}`
            }
            const res = await loggingService.getAuthen(`retry/pending?${filter}`)
            if (res.status === 200) {
              this.$refs.table.refresh()
              this.$swal({
                title: 'Success!',
                text: 'ดำเนินการสำเร็จ ระบบเตรียมส่งข้อมูลอีกครั้ง',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            } else {
              this.$swal({
                title: 'Error!',
                text: res.data,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          }
        })
    },
    showUserDetial(username) {
      this.$router.push({
        name: 'members-edit',
        query: { id: username },
      })
    },
    openRequestModal(data) {
      this.requestData = JSON.parse(data)
      this.requestModal = true
    },
    openResponseModal(data) {
      this.responseData = JSON.parse(data)
      this.responseModal = true
    },
    filterSearch() {
      this.filter = this.filterForSearch
    },
    async reloadData() {
      const startRow = (this.currentPage - 1) * this.perPage
      if (this.filter === '') {
        return []
      }

      this.loading = true
      const res = await loggingService.getAuthen(`retry/list?${this.filter}&limit=${this.perPage}&page=${startRow}&sortBy=${this.sortBy}&desc=${this.sortDesc}`)
      if (res.status === 200) {
        this.totalRows = res.data.count
        this.loading = false
        const { list } = res.data
        if (this.sortDesc === false) {
          let index = startRow + 1
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index += 1
          }
        } else {
          let index = res.data.count
          for (let i = 0; i < list.length; i += 1) {
            list[i].index = index
            index -= 1
          }
        }
        return list
      }
      this.loading = false
      return []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

table {
  .sr-only {
    width: 0px !important;
  }

  .dropdown-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

.btn-group {
  .btn {
    padding: 5px;
  }
}

.text-effect:hover {
  font-weight: bolder;
}

.h-100[role="columnheader"] {
  height: auto !important;
}

.h-100 {
  height: 100px !important;
}
</style>
